:root {
  --navbar-height: 80px;
  --home-our-apps-pic-width: 300px;
  --home-our-apps-pic-height: 150px;
  --week-container-width: 40px;
  --day-container-width: 100px;
  --day-date-width: 80px;
  --shift-container-width: 120px;
  --PP-heading-height: 50px;
  --app-main-color: #f8c12c;
  --app-gray-color: #464646;
  --blocked-gray: #c7c7c7;
  --app-font-main: "Raleway", sans-serif;
  --app-font-secondary: "Roboto", sans-serif;
  --app-font-thirdary: "Hind", sans-serif;
  --bg-img-pattern: url(/static/media/bg-img.bfd35f22.jpg);
}

html {
  scroll-behavior: smooth !important;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.cursor-pointer {
  cursor: pointer !important;
}

.x-close-btn {
  position: absolute;
  color: white;
  top: calc(80px + 0.5em);
  top: calc(var(--navbar-height) + 0.5em);
  right: 0.5em;
  font-size: 2em !important;
}

.min-hv-100-no-navbar {
  min-height: calc(100vh - 80px);
  min-height: calc(100vh - var(--navbar-height));
}

.app-modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 999; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

.app-modal-display {
  display: block !important;
}

.table-nonfluid {
  width: auto !important;
}

.custom-navbar {
  height: 80px !important;
  height: var(--navbar-height) !important;
  background-color: #000000 !important;
  transition: 0.5s !important;
  border-bottom: 1px solid gray !important;
  box-shadow: 0 4px 15px -2px gray;
}

.custom-navLink {
  text-transform: uppercase !important;
  text-decoration: none !important;
  color: #ffffff !important;
  font-weight: bold !important;
  transition: 0.2s !important;
  font-family: "Raleway", sans-serif, sans-serif !important;
  font-family: var(--app-font-main), sans-serif !important;
}

.custom-navLink:hover,
.custom-navLink.active:hover {
  background: #fff !important;
  color: #000 !important;
  transition: 0.1s !important;
  box-shadow: 0px 0px 1px 0px #000000;
}

.custom-navLink.active {
  background: #fff !important;
  color: #000 !important;
  transition: 0.1s !important;
}

.custom-navbar-collapse {
  background-color: #000000 !important;
  z-index: 999;
}

.loading-over-everything {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(15, 15, 15, 0.98);
  z-index: 999;
}

.app-font-main {
  font-family: "Raleway", sans-serif !important;
  font-family: var(--app-font-main) !important;
}

.app-font-secondary {
  font-family: "Roboto", sans-serif !important;
  font-family: var(--app-font-secondary) !important;
}

.app-font-thirdary {
  font-family: "Hind", sans-serif !important;
  font-family: var(--app-font-thirdary) !important;
}

.icon-stuff {
  font-size: 4em !important;
  margin-top: 0.4em !important;
  margin-bottom: 0.4em !important;
}

.bg-black {
  background-color: rgb(0, 0, 0) !important;
}

.bg-app-main-color {
  background-color: #f8c12c !important;
  background-color: var(--app-main-color) !important;
}

.bg-img-dark {
  min-height: 100%;
  background: linear-gradient(
      270deg,
      rgba(255, 255, 255, 0.99),
      1%,
      rgba(0, 0, 0, 0.99)
    ),
    url(/static/media/bg-img.bfd35f22.jpg) no-repeat center center fixed;
  background: linear-gradient(
      270deg,
      rgba(255, 255, 255, 0.99),
      1%,
      rgba(0, 0, 0, 0.99)
    ),
    var(--bg-img-pattern) no-repeat center center fixed;
  background-size: cover;
  transition: 3s;
}

.bg-img {
  min-height: 100%;
  background: linear-gradient(
      270deg,
      rgba(255, 255, 255, 0.99),
      35%,
      rgba(255, 255, 255, 0.99)
    ),
    url(/static/media/bg-img.bfd35f22.jpg) no-repeat center center fixed;
  background: linear-gradient(
      270deg,
      rgba(255, 255, 255, 0.99),
      35%,
      rgba(255, 255, 255, 0.99)
    ),
    var(--bg-img-pattern) no-repeat center center fixed;
  background-size: cover;
}

.text-app-main-color {
  color: #f8c12c !important;
  color: var(--app-main-color) !important;
}

.container-white-trans {
  background-color: rgba(255, 255, 255, 0.99);
  border-radius: 8px;
  box-shadow: 0px 0px 7px 0px white;
  padding: 25px;
}

.container-gray-trans {
  background-color: rgba(250, 250, 250, 0.9);
  border-radius: 8px;
  box-shadow: 0px 0px 7px 0px gainsboro;
  padding: 25px;
}

.slider-width-reduction {
  width: 50% !important;
  margin: auto;
}

.clickable-card-container {
  width: 300px;
  height: 200px;
  border-radius: 5px;
  box-shadow: 0px 0px 7px 0px #000000;
  position: relative;
}

.clickable-card-container:hover {
  background-color: rgba(32, 32, 32);
  transition: 0.1s;
}

.clickable-card-text-cover {
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
  background-color: rgba(32, 32, 32, 0.85);
  border-radius: 5px;
  position: absolute !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.clickable-card-text-cover > h4 {
  color: #ffffff !important;
  font-size: 3em !important;
}

.heading-underline {
  border-bottom: 2px solid #f8c12c;
  border-bottom: 2px solid var(--app-main-color);
}

.home-welcome-img-container {
  background-image: url(/static/media/welcome-bg.6213a26b.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-width: 100%;
}

.home-welcome-img-texts {
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
  min-width: 100%;
  padding-top: 10%;
  padding-bottom: 10%;
}

.home-welcome-img-welcoming-text {
  color: #f8c12c;
  color: var(--app-main-color);
  font-family: "Hind", sans-serif;
  font-family: var(--app-font-thirdary);
  font-size: 2rem;
}

.home-welcome-img-brand-text {
  color: #fff;
  font-family: "Hind", sans-serif;
  font-family: var(--app-font-thirdary);
  font-size: 5rem;
  line-height: 80%;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 10px;
  letter-spacing: 5px;
}

.home-welcome-img-additional-text {
  color: #fff;
  font-family: "Hind", sans-serif;
  font-family: var(--app-font-thirdary);
  font-size: 1.2rem;
}

.home-welcome-img-buttons-container {
  margin-top: 10px;
}

.home-welcome-img-button-first {
  background-color: #f8c12c !important;
  background-color: var(--app-main-color) !important;
  color: #000000 !important;
  font-family: "Raleway", sans-serif;
  font-family: var(--app-font-main);
  font-weight: bold !important;
  margin-right: 10px;
  /* text-transform: uppercase; */
}

.home-welcome-img-button-second {
  background-color: rgba(0, 0, 0, 0) !important;
  color: #fff !important;
  font-family: "Raleway", sans-serif;
  font-family: var(--app-font-main);
  /* font-weight: bold !important; */
  border: 1px solid #fff !important;
  /* text-transform: uppercase; */
}

.home-info-box-cover {
  background-image: url(/static/media/info-bg.f9d23c9c.png);
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
}

.home-info-box-backdrop {
  -webkit-backdrop-filter: blur(2px);
          backdrop-filter: blur(2px);
}

.home-info-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.home-info-box-icon {
  font-size: 500% !important;
  color: #464646;
  color: var(--app-gray-color);
}

.home-info-box-heading {
  font-family: "Roboto", sans-serif;
  font-family: var(--app-font-secondary);
  color: #464646;
  color: var(--app-gray-color);
  margin-top: 20px;
  margin-bottom: 20px;
}

.home-info-box-text {
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 0;
  font-family: "Raleway", sans-serif;
  font-family: var(--app-font-main);
}

.home-our-apps-container {
  background-image: url(/static/media/our-apps-bg.5b63aaec.jpg);
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
}

.home-our-apps-backdrop {
  -webkit-backdrop-filter: blur(3px);
          backdrop-filter: blur(3px);
}

.home-our-apps-list-item {
  font-family: "Hind", sans-serif;
  font-family: var(--app-font-thirdary);
  text-transform: uppercase;
  font-weight: bold;
}

.home-our-apps-card {
  border-radius: 0 !important;
}

.home-our-apps-list-group {
  border-radius: 0 !important;
}

.home-our-apps-list-item-active {
  background-color: #f8c12c !important;
  background-color: var(--app-main-color) !important;
}

.home-our-apps-card-title {
  font-family: "Raleway", sans-serif;
  font-family: var(--app-font-main);
  font-weight: bold;
}

.home-our-apps-card-text {
  font-family: "Hind", sans-serif;
  font-family: var(--app-font-thirdary);
}

.home-our-apps-pic-container {
  position: relative;
  width: 300px;
  width: var(--home-our-apps-pic-width);
  max-width: 100%;
}

.home-our-apps-pic {
  display: block;
  width: 300px;
  width: var(--home-our-apps-pic-width);
  height: 150px;
  height: var(--home-our-apps-pic-height);
  max-width: 100%;
  border: 2px solid gray;
}

.home-our-apps-pic-overlay {
  width: 300px;
  width: var(--home-our-apps-pic-width);
  max-width: 100%;
  height: 150px;
  height: var(--home-our-apps-pic-height);
  transition: 0.5s ease;
  opacity: 0.9;
  background-color: black;
  position: absolute;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 5em;
  font-family: "Roboto", sans-serif;
  font-family: var(--app-font-secondary);
}

.home-our-apps-btn {
  background-color: #f8c12c !important;
  background-color: var(--app-main-color) !important;
}

.home-our-apps-viewer-container {
  background-color: black;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  z-index: 999;
}

.home-contact-container {
  background-image: url(/static/media/contact-bg.fdd7a3ae.jpg);
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
  color: #464646;
  color: var(--app-gray-color);
}

.home-contact-backdrop {
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
}

.home-contact-icon {
  font-size: 500% !important;
  color: #464646;
  color: var(--app-gray-color);
}

.home-contact-title {
  font-family: "Roboto", sans-serif;
  font-family: var(--app-font-secondary);
  color: #464646;
  color: var(--app-gray-color);
}

.home-contact-text {
  font-family: "Roboto", sans-serif;
  font-family: var(--app-font-secondary);
  color: white;
  background-color: #464646;
  background-color: var(--app-gray-color);
  padding: 5px;
  border-radius: 3px;
}

.home-footer-container {
  background-color: #464646;
  background-color: var(--app-gray-color);
  color: #fff;
}

.home-footer-col-title {
  color: #f8c12c;
  color: var(--app-main-color);
  text-align: center;
  font-family: "Hind", sans-serif;
  font-family: var(--app-font-thirdary);
  text-transform: uppercase;
}

.home-footer-list {
  list-style: none;
  font-family: "Roboto", sans-serif;
  font-family: var(--app-font-secondary);
}

.home-footer-list > li {
  margin-top: 5px;
  margin-bottom: 5px;
}

.home-footer-icon {
  margin-right: 30px;
  color: #f8c12c;
  color: var(--app-main-color);
}

.home-footer-col-1 {
  font-family: "Hind", sans-serif;
  font-family: var(--app-font-thirdary);
  text-align: center;
}

.home-footer-col-2 {
  padding-left: 10px;
}

.home-footer-col-3 {
  text-transform: uppercase;
  text-align: center;
  padding: 0;
}

.home-footer-col-4 {
  text-align: center;
  padding: 0;
}

.home-footer-col-border {
  border-right: 1px solid gray;
}

.home-bottom-footer {
  background-color: #000000;
  color: #464646;
  color: var(--app-gray-color);
  font-family: "Roboto", sans-serif;
  font-family: var(--app-font-secondary);
}

.move-top-button {
  position: fixed;
  bottom: 20px;
  right: 30px;
  color: white;
  background-color: black;
  padding-top: 13px;
  padding-bottom: 13px;
  padding-left: 17px;
  padding-right: 17px;
  border-radius: 50px;
  box-shadow: 0px 0px 2px white;
}

.move-top-button:focus,
.move-top-button:hover {
  color: gray;
}

.help-content-section {
  padding: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid rgba(128, 128, 128, 0.1);
}

.help-content-heading {
  font-family: "Hind", sans-serif;
  font-family: var(--app-font-thirdary);
}

.apps-not-paid {
  background-color: #dc3545;
  border-radius: 5px;
}

.shift-col-heading {
  width: 120px;
  width: var(--shift-container-width);
  height: 50px;
  height: var(--PP-heading-height);
  text-align: center;
  display: grid;
  place-content: center;
  background-color: black;
  color: white;
  font-family: "Hind", sans-serif;
  font-family: var(--app-font-thirdary);
  border: 1px solid #adabab;
}

.col-under-shift-col-heading {
  margin-top: 50px;
  margin-top: var(--PP-heading-height);
}

.just-week {
  width: 40px;
  width: var(--week-container-width);
  background-color: rgb(97, 94, 94);
  color: white;
  border: 1px solid #adabab;
  font-weight: bold;
  display: grid;
  place-content: center;
}

.just-day {
  width: 100px;
  width: var(--day-container-width);
  background-color: rgba(223, 217, 217, 0.7);
  border: 1px solid #adabab;
  font-weight: bold;
  display: grid;
  place-content: center;
}

.just-day-date {
  width: 80px;
  width: var(--day-date-width);
}

.just-day-shift {
  height: 100%;
  width: calc(100px - 80px);
  width: calc(var(--day-container-width) - var(--day-date-width));
  border-left: 2px solid #adabab;
}

.just-day-shift > div {
  width: 100% !important;
  display: grid;
  place-content: center;
}

.weekend-day {
  background-color: rgba(172, 172, 172, 0.7);
}

.shift-day {
  width: 120px;
  width: var(--shift-container-width);
  font-weight: bold;
  display: grid;
  place-content: center;
  border-left: 2px solid #adabab;
  border-right: 2px solid #adabab;
}

.event {
  cursor: pointer;
}

.blockedEvent {
  cursor: pointer;
  background-image: linear-gradient(
    135deg,
    #ffffff 5%,
    #c7c7c7 5%,
    #c7c7c7 10%,
    #ffffff 10%,
    #ffffff 15%,
    #c7c7c7 15%,
    #c7c7c7 20%,
    #ffffff 20%,
    #ffffff 25%,
    #c7c7c7 25%,
    #c7c7c7 30%,
    #ffffff 30%,
    #ffffff 35%,
    #c7c7c7 35%,
    #c7c7c7 40%,
    #ffffff 40%,
    #ffffff 45%,
    #c7c7c7 45%,
    #c7c7c7 50%,
    #ffffff 50%,
    #ffffff 55%,
    #c7c7c7 55%,
    #c7c7c7 60%,
    #ffffff 60%,
    #ffffff 65%,
    #c7c7c7 65%,
    #c7c7c7 70%,
    #ffffff 70%,
    #ffffff 75%,
    #c7c7c7 75%,
    #c7c7c7 80%,
    #ffffff 80%,
    #ffffff 85%,
    #c7c7c7 85%,
    #c7c7c7 90%,
    #ffffff 90%,
    #ffffff 95%,
    #c7c7c7 95%,
    #c7c7c7 100%
  );
  background-image: linear-gradient(
    135deg,
    #ffffff 5%,
    var(--blocked-gray) 5%,
    var(--blocked-gray) 10%,
    #ffffff 10%,
    #ffffff 15%,
    var(--blocked-gray) 15%,
    var(--blocked-gray) 20%,
    #ffffff 20%,
    #ffffff 25%,
    var(--blocked-gray) 25%,
    var(--blocked-gray) 30%,
    #ffffff 30%,
    #ffffff 35%,
    var(--blocked-gray) 35%,
    var(--blocked-gray) 40%,
    #ffffff 40%,
    #ffffff 45%,
    var(--blocked-gray) 45%,
    var(--blocked-gray) 50%,
    #ffffff 50%,
    #ffffff 55%,
    var(--blocked-gray) 55%,
    var(--blocked-gray) 60%,
    #ffffff 60%,
    #ffffff 65%,
    var(--blocked-gray) 65%,
    var(--blocked-gray) 70%,
    #ffffff 70%,
    #ffffff 75%,
    var(--blocked-gray) 75%,
    var(--blocked-gray) 80%,
    #ffffff 80%,
    #ffffff 85%,
    var(--blocked-gray) 85%,
    var(--blocked-gray) 90%,
    #ffffff 90%,
    #ffffff 95%,
    var(--blocked-gray) 95%,
    var(--blocked-gray) 100%
  );
}

.empty {
  cursor: default;
  background-color: white;
}

.eventDone {
  cursor: pointer;
  background-color: rgb(0, 0, 0, 0.4) !important;
  color: rgb(0, 0, 0, 0.2) !important;
  background-image: linear-gradient(
      to bottom right,
      transparent calc(50% - 1px),
      rgba(255, 0, 0, 0.3),
      transparent calc(50% + 1px)
    ),
    linear-gradient(
      to bottom left,
      transparent calc(50% - 1px),
      rgba(255, 0, 0, 0.3),
      transparent calc(50% + 1px)
    );
}

.horizontally-scrollable {
  overflow-x: auto !important;
  overflow-y: hidden !important;
  white-space: nowrap !important;
}

.custom-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 9999;
}

.connected-users {
  width: 150px;
  background-color: #ffc1077a;
  border: 1px solid #ffc107;
  border-radius: 5px;
  padding: 20px;
  list-style: none;
}

.line-row {
  background-color: rgb(206, 205, 205);
  border-radius: 5px;
  padding: 5px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.right-side-icon {
  font-size: 2em !important;
  padding: 8px 16px !important;
}

.searched-shift {
  width: 120px;
  width: var(--shift-container-width);
  height: 50px;
  display: grid;
  place-content: center;
  border: 1px solid #adabab;
  background-color: #adabab48;
}

.searched-shift > :nth-child(2) {
  font-size: 80%;
}

@media (max-width: 1024px) {
  .icon-stuff {
    font-size: 2.5em !important;
    margin-top: 0em !important;
    margin-bottom: 0em !important;
  }
}

@media (max-width: 768px) {
  .slider-width-reduction {
    width: 100% !important;
  }

  .home-welcome-img-welcoming-text {
    font-size: 1.2rem;
  }

  .home-welcome-img-brand-text {
    font-size: 3rem;
  }

  .home-welcome-img-additional-text {
    font-size: 0.9rem;
  }

  .home-footer-col-border:nth-child(2) {
    border: none !important;
  }
}

@media (max-width: 480px) {
  .icon-stuff {
    font-size: 1.5em !important;
    margin-top: 0em !important;
    margin-bottom: 0em !important;
  }

  .home-footer-col-border {
    border: none;
  }

  .right-side-icon {
    font-size: 1em !important;
    padding: 3px 8px !important;
  }
}

/*LANDSCAPE MODE SPECIAL FIXES*/
@media (max-width: 767px) and (orientation: landscape) {
  .home-footer-col-border {
    border: none;
  }

  .right-side-icon {
    font-size: 1.5em !important;
    padding: 4px 9px !important;
  }
}

@media (max-width: 1023px) and (orientation: landscape) {
  .home-footer-col-border:nth-child(2) {
    border: none !important;
  }
}

/* WORKAROUND FOR BACKDROP-FILTER (not supporting backdrop-filter) */
@supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  .clickable-card-text-cover,
  .home-welcome-img-texts,
  .home-our-apps-backdrop {
    background-color: rgba(0, 0, 0, 0.9);
  }

  .home-info-box-backdrop,
  .home-contact-backdrop {
    background-color: rgba(255, 255, 255, 0.8);
  }
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

